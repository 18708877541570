
import { defineComponent, computed } from "vue"
import { useStore } from "vuex"

export default defineComponent({
  name: "Loader",
  props: {
    logo: String,
  },
  setup() {
    const store = useStore()

    const loaderType = computed(() => {
      return store.getters.layoutConfig("loader.type")
    })

    return {
      loaderType,
    }
  },
})
