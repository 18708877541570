
import { defineComponent } from "vue"
import { footerWidthFluid } from "@/core/helpers/config"

export default defineComponent({
  name: "KTFooter",
  setup() {
    const appUrl = process.env.VUE_APP_URL
    return {
      footerWidthFluid,
      appUrl,
    }
  },
})
