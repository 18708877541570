import store from "@/store"
import { Actions } from "@/store/enums/StoreEnums"

/**
 * Sets current page breadcrumbs
 * @param {string} pageTitle Current page title
 * @param {Array<string>} breadcrumbs Current page breadcrumbs
 */
export const setCurrentPageBreadcrumbs = (pageTitle: string, breadcrumbs: Array<string>): void => {
  store.dispatch(Actions.SET_BREADCRUMB_ACTION, {
    title: pageTitle,
    pageBreadcrumbPath: breadcrumbs,
  })
}

/**
 * Sets current page breadcrumbs
 * @param {string} title Current page title name
 */
export const setCurrentPageTitle = (title: string): void => {
  store.dispatch(Actions.SET_BREADCRUMB_ACTION, {
    title: title,
  })
}

export const searchMenuTree = (element, matchingRoute, parentId = "") => {
  if (parentId == "") parentId = element.id
  if (element.url == matchingRoute) {
    return element
  } else if (element.childMenuList != null) {
    let i
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let result: any = null
    for (i = 0; result == null && i < element.childMenuList.length; i++) {
      result = searchMenuTree(element.childMenuList[i], matchingRoute, parentId)
    }
    if (result == null) return null
    else return { ...result, parentId: parentId }
  }
  return null
}
