
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from "vue"
import { useRoute } from "vue-router"
import { useI18n } from "vue-i18n/index"
import { headerMenuIcons } from "@/core/helpers/config"
import { version } from "@/core/helpers/documentation"
import { useStore } from "vuex"

export default defineComponent({
  name: "KTMenu",
  components: {},
  props: {
    menuId: String,
  },
  setup(props) {
    const { t, te } = useI18n()
    const route = useRoute()
    const store = useStore()
    const menuIdState = ref<string | undefined>("")

    // init component
    onMounted(() => {
      getDimensions()
      window.addEventListener("resize", getDimensions)
    })

    // remove event listener after destroying the component
    onUnmounted(() => {
      menuIdState.value = props.menuId
      window.removeEventListener("resize", getDimensions)
    })

    //TODO: genele alınmalı. css deki _variables dosyası ile bir olmalı
    const breakPoints = {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
    }

    // get template ref dimensions
    function getDimensions() {
      const rect = document.documentElement.getBoundingClientRect()
      if (rect.width < breakPoints.lg) menuIdState.value = ""
      else menuIdState.value = props.menuId
    }

    watch(
      () => props.menuId,
      first => {
        menuIdState.value = first
      }
    )

    // watch(
    //   () => store.getters.getSubMenuList(menuIdState.value),
    //   function (newValue) {
    //     if (newValue.length > 0) {
    //       for (let index = 0; index < newValue.length; index++) {
    //         const element = newValue[index]
    //         let temp = searchMenuTree(element, route.path)
    //         if (temp != null) selectedMenuId.value = temp.parentId
    //       }
    //     }
    //   }
    // )

    const subMenuList = computed(() => {
      const result = store.getters.getSubMenuList(menuIdState.value)
      if (result) return result
      else return []
    })

    const hasActiveChildren = menu => {
      if (!menu) return false
      if (!menu.childMenuList || menu.childMenuList.length == 0)
        return menu.url.indexOf(route.path) > -1
      else return menu.childMenuList.map(x => x.url).indexOf(route.path) > -1
    }

    const translate = text => {
      if (te(text)) {
        return t(text)
      } else {
        return text
      }
    }

    return {
      menuIdState,
      subMenuList,
      hasActiveChildren,
      headerMenuIcons,
      translate,
      version,
    }
  },
})
