
import { computed, defineComponent, onMounted, ref, watch } from "vue"
import KTMenu from "@/layout/header/Menu.vue"

import { headerWidthFluid, headerLeft, asideDisplay } from "@/core/helpers/config"
import { useStore } from "vuex"
import { Actions } from "../../store/enums/StoreEnums"
import { useRoute, useRouter } from "vue-router"
import { searchMenuTree } from "@/core/helpers/breadcrumb"
import { ElNotification } from "element-plus"

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTMenu,
  },
  setup() {
    const selectedMenuId = ref()
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    onMounted(() => {
      selectedMenuId.value = getSelectedMenuId(store.getters.getMenuList, route.meta.rootPath)
    })

    const getSelectedMenuId = (menuList, routePath) => {
      for (let index = 0; index < menuList.length; index++) {
        const element = menuList[index]
        let temp = searchMenuTree(element, routePath)
        if (temp != null) return temp.parentId
      }
    }

    watch(
      () => store.getters.getMenuList,
      function (newValue) {
        if (!newValue) {
          ElNotification({
            title: "Menü",
            dangerouslyUseHTMLString: true,
            message: "Kullanıcı için atanmış menü yok",
            type: "error",
            duration: 3000,
          })
        }
        selectedMenuId.value = getSelectedMenuId(newValue, route.meta.rootPath)
      }
    )

    const accountList = computed(() => {
      return store.getters.getAccountList
    })
    const activeAccount = computed(() => {
      return store.getters.activeAccount
    })
    const currentUser = computed(() => {
      return store.getters.currentUser
    })
    const setActiveAccount = async accountId => {
      await store.dispatch(Actions.SET_ACTIVE_ACCOUNT, accountId)
      router.push({ name: "dashboard-summary" })
      await store.dispatch(Actions.GET_MENU)
      await store.dispatch(Actions.GET_ACCOUNT)
    }
    const selectedLanguage = ref("Türkçe")
    function isActive(menu) {
      return selectedMenuId.value == menu.id
    }
    const changeLanguage = event => {
      selectedLanguage.value = event.target.textContent.trim()
      router.push({ name: "dashboard-summary" })
    }
    function logOut() {
      store.dispatch(Actions.LOGOUT)
      router.push({ name: "sign-in" })
    }
    return {
      selectedMenuId,
      isActive,
      menuList: computed(() => store.getters.getMenuList),
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      logOut,
      selectedLanguage,
      changeLanguage,
      accountList,
      activeAccount,
      currentUser,
      setActiveAccount,
      router,
    }
  },
})
